#root {
    /* Expands root div that all app content renders onto to take up all available window height */
    height: 100%;
}
.loginGrid, .loadingContainer {
    /* Login container and loading swirly container grid will inherit height of root div so it can work out how to v-align login form */
    height: inherit;
}

.mainContainer {
    /* mainContainer that holds all rendered content (after routers) inherits root's 100% height */
    height: inherit;
    margin-left: 0!important;
    margin-right: 0!important;
}
.incidentFormSegment, .profileForm, .quickFormSegment, .adminCSVSegment {
    width: 100%;
}
.DayPickerInput {
    /* Extends container for <input class="DayPickerInput" /> to fit it's fill width in forms*/
    width: 100%;
}
input[name="incidentDate"] {
    width: inherit!important;
}
.menuBarLogo{
    margin-top: 8px !important;
}
.DayPickerInput-OverlayWrapper {
    /* Ensures that the overlay for the popup calendar for input field stays ABOVE all other input
    fields. Removing this causes semantic input fields to be on top of the calendar. 999999 is abitrary */
    z-index: 999999;
}
.assistanceRenderedNumber {
    font-size: 2em;
    margin: 0;
}
.assistanceRenderedIcon {
    /* adds spacing between the icon and the number and the bottom text */
    margin-right: 10px!important;
    margin-bottom: 10px!important;
}
.with14pxBottomPadding {
    /* adds a little padding at bottom of class */
    padding-bottom: 14px!important;
}
.boldText {
    font-weight: bold;
}
.profileUserDetailIcons {
    width: 38px!important;
}

/* For making the dropdown scroll of form sections taller */
@media only screen and (max-width: 767px) {
    .ui.selection.dropdown .menu {
        max-height: 16.01428571rem;
    }
}

/* Ensures the content pane always extends to the 'bottom' of the view window minus the height of the menu bar so the side nav is always visible */
.pusher, .minHeightInPusher {
    min-height: calc(100vh - 59px)!important;
}

/* Spaces out the heading of each section below the menu bar */
.sectionHeader {
    padding-top: 1em!important;
}